/* eslint-disable */

'use strict'
import $ from "jquery";
import UIkit from "UIkit";
import Inputmask from "inputmask";
// import {  TweenMax} from "gsap";
// import datetimepicker from "jquery-datetimepicker";
import marquee from "jquery.marquee";

import 'lazysizes';
// import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
// import { tree } from "gulp";
import Scrollbar from 'smooth-scrollbar';

import {
  ymap as ymap
} from './map-contact';
// import {ymap as ymapHowDoMetro} from './map-how-do-metro';
// import {ymap as ymapHowDoAuto} from './map-how-do-auto';

// $('.klademug').each(function () {
//   $(this).prop('Counter', 0).animate({
//     Counter: 100
//   }, {
//     duration: 2000,
//     easing: 'swing',
//     step: function (now) {
//       $(this).text(Math.ceil(now) + '%');
//     }
//   });
// });

// функция возвращает cookie с именем name, если есть, если нет, то undefined    
// function getCookie(name) {
//   let matches = document.cookie.match(new RegExp(
//   "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//   ));
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }
// let cookiecook = getCookie("cookiecook"),
// cookiewin = document.getElementsByClassName('cookie-warning')[0];    
// // проверяем, есть ли у нас cookie, с которой мы не показываем окно и если нет, запускаем показ
// if (cookiecook != "no") {
//   // показываем    
//   cookiewin.style.display="block"; 
//   // закрываем по клику
//   document.getElementById("cookie-close-2").addEventListener("click", function(){
//     cookiewin.style.display="none";    
//     // записываем cookie на 1 день, с которой мы не показываем окно
//     let date = new Date;
//     date.setDate(date.getDate() + 30);    
//     document.cookie = "cookiecook=no; path=/; expires=" + date.toUTCString();               
//   });
// }


if ($(window).width() > 639) {
  setTimeout(() => {
    let preloaderEl2 = document.getElementById('preloader2');
    let preloaderEl1 = document.getElementById('preloader1');
    $('body').attr('style', '');
    if (preloaderEl2) {
      preloaderEl2.classList.add('hidden');
    }
    if (preloaderEl1) {
      preloaderEl1.classList.add('hidden');
    }
    $('.header-top__item1 .uk-container').attr('uk-scrollspy', 'cls:uk-animation-slide-top; delay: 500;');
    $('.main-banner__bottom').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 500;');

    $('.main-banner__citys').attr('uk-scrollspy', 'cls:uk-animation-fade; delay: 700;');
    $('.main-banner__h1').attr('uk-scrollspy', 'cls:uk-animation-fade; delay: 700;');
    $('.map1 ').attr('uk-scrollspy', 'cls:uk-animation-slide-right; delay: 400;');

    $('.js-animate-bottom-target-in').attr('uk-scrollspy', 'cls:active; delay: 200;');
    $('.portfolio-inner-cards').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 200; target:.portfolio-inner-card;');




  }, 4000);

} else {
  setTimeout(() => {
    let preloaderEl2 = document.getElementById('preloader2');
    let preloaderEl1 = document.getElementById('preloader1');
    $('body').attr('style', '');
    if (preloaderEl2) {
      preloaderEl2.classList.add('hidden');
    }
    if (preloaderEl1) {
      preloaderEl1.classList.add('hidden');
    }

    $('.header-top__item1 .uk-container').attr('uk-scrollspy', 'cls:uk-animation-slide-top; delay: 500;');
    $('.main-banner__bottom').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 500;');

    $('.main-banner__citys').attr('uk-scrollspy', 'cls:uk-animation-fade; delay: 700;');
    $('.main-banner__h1').attr('uk-scrollspy', 'cls:uk-animation-fade; delay: 700;');
    $('.map1 ').attr('uk-scrollspy', 'cls:uk-animation-slide-right; delay: 400;');

    $('.js-animate-bottom-target-in').attr('uk-scrollspy', 'cls:active; delay: 200;');
    $('.portfolio-inner-cards').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 200; target:.portfolio-inner-card;');


  }, 1000);

}




$(document).ready(function () {

  const canvas = document.getElementById('myCanvas');
  const ctx = canvas.getContext('2d');
  canvas.width = window.innerWidth;
  canvas.height = document.body.scrollHeight;
  const particlesArray = [];
  let hue = 0;
  let frame = 0;

  window.addEventListener('resize', function () {
    canvas.width = window.innerWidth;
    canvas.height = document.body.scrollHeight;
  });
  document.addEventListener('scroll', function () {
    canvas.height = document.body.scrollHeight;
  });


  const mouse = {
    x: undefined,
    y: undefined,
  }


  document.addEventListener('mousemove', function (event) {
    canvas.height = document.body.scrollHeight;

    mouse.x = event.x;
    mouse.y = event.pageY;
    hue += 2;
    if (frame % 2 === 0) {
      for (let i = 0; i < 7; i++) {
        particlesArray.push(new Particle());
      }
    }
  });

  class Particle {
    constructor() {
      this.x = mouse.x;
      this.y = mouse.y;
      this.size = Math.random() * 15 + 1;
      this.speedX = Math.random() * 3 - 1.5;
      this.speedY = Math.random() * 3 - 1.5;
      this.color = 'hsl(' + hue + ', 100%, 50%)';
    }
    update() {
      this.x += this.speedX;
      this.y += this.speedY;
      if (this.size > 0.2) this.size -= 0.1;
    }
    draw() {
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fill();
    }
  }

  function handleParticles() {
    for (let i = 0; i < particlesArray.length; i++) {
      for (let j = i; j < particlesArray.length; j++) {
        const dx = particlesArray[i].x - particlesArray[j].x;
        const dy = particlesArray[i].y - particlesArray[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 100) {
          ctx.beginPath();
          ctx.strokeStyle = particlesArray[i].color;
          ctx.lineWidth = 0.2;
          ctx.moveTo(particlesArray[i].x, particlesArray[i].y);
          ctx.lineTo(particlesArray[j].x, particlesArray[j].y);
          ctx.stroke();
          ctx.closePath();
        }
      }
      particlesArray[i].update();
      particlesArray[i].draw();

      if (particlesArray[i].size <= 0.3) {
        particlesArray.splice(i, 1);

        i--;
      }
    }
  }

  function animate() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    //ctx.fillStyle = 'rgba(0,0,0,0.02)';
    //ctx.fillRect(0, 0, canvas.width, canvas.height);
    handleParticles();
    frame++;
    requestAnimationFrame(animate);
  }
  animate();

  var workMainSlider = new Swiper(".work-main__slider", {
    spaceBetween: 20,
    slidesPerView: 1.1,

    navigation: {
      nextEl: ".work-main-slider__arrow-right",
      prevEl: ".work-main-slider__arrow-left",
    },
    scrollbar: {
      el: '.work-main-slider__scrollbar',
      hide: false,
      draggable: true
    },
    breakpoints: {
      // when window width is >= 320px
      639: {
        spaceBetween: 28,
        slidesPerView: 2.5,
      },
      1199: {
        // spaceBetween: 38,
        slidesPerView: 3,
      },
      1450: {
        spaceBetween: 40,
        slidesOffsetBefore: 0,
        slidesPerView: 3.7,

      },
    },
  });




  // var img = $('.advantages__triangle');
  // if(img.length > 0){
  //     var offset = img.offset();
  //     console.log(offset.left, offset.top)
  //     function mouse(evt){
  //         var center_x = (offset.left);
  //         var center_y = (offset.top);
  //         var mouse_x = evt.pageX; 
  //         var mouse_y = evt.pageY;
  //         var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
  //         var degree = (radians * (180 / Math.PI) * -1) + 90; 
  //         img.css('-moz-transform', 'translate(-50%, -100%) rotate('+degree+'deg)');
  //         img.css('-webkit-transform', 'translate(-50%, -100%) rotate('+degree+'deg)');
  //         img.css('-o-transform', 'translate(-50%, -100%) rotate('+degree+'deg)');
  //         img.css('-ms-transform', 'translate(-50%, -100%) rotate('+degree+'deg)');
  //     }
  //     $(document).mousemove(mouse);
  // }



  if (document.querySelector('.advantages__triangle')) {
    // Получаем нужный элемент
    var element = document.querySelector('.advantages__section');
    var Visible = function (target) {
      // Все позиции элемента
      var targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight
        };


      if (targetPosition.bottom > windowPosition.top + 600 && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        targetPosition.top < windowPosition.bottom - 600 && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код

        document.querySelector(".advantages__triangle").classList.remove('uk-hidden');
      } else {
        // Если элемент не видно, то запускаем этот код

        document.querySelector(".advantages__triangle").classList.add('uk-hidden');
      };
    };

    // Запускаем функцию при прокрутке страницы
    window.addEventListener('scroll', function () {
      Visible(element);
    });

    document.addEventListener('mousemove', function (event) {
      var div = document.querySelector(".advantages__triangle");
      var bb = div.getBoundingClientRect();
      var cx = bb.left + bb.width / 2,
        cy = bb.top + bb.height / 2;

      var angle = Math.atan2(event.y - cy, event.x - cx);

      div.style.transform = "translate(-50%, -50%) rotate(" + angle + "rad)"
    })
  }


  UIkit.util.on('.header-top__item1', 'active', function () {
    $('body').addClass('sticky-active');
  });
  UIkit.util.on('.header-top__item1', 'inactive', function () {
    $('body').removeClass('sticky-active');
  });

  // Вывод карты на странице контакты
  if ($('.map-contact').length > 0) {
    ymap();
  }

  Array.from(document.querySelectorAll('.modal-news')).map(el => {
    const elNextNews = el.querySelector('.modal-news__right');
    const elNextNewsHref = elNextNews.getAttribute('href');
    const elNextNewsModal = document.querySelector(elNextNewsHref);

    const elprevNews = el.querySelector('.modal-news__left');
    const elprevNewsHref = elprevNews.getAttribute('href');
    const elprevNewsModal = document.querySelector(elprevNewsHref);

    const elId = '#' + el.getAttribute('id');
    const elClose1 = el.querySelector('.uk-modal-close-default');
    const elClose2 = el.querySelector('.uk-modal-close');

    var handler = function () {
      UIkit.util.on(el, 'hide', function (e) {
        $('.modal-news .uk-modal-dialog').css('transform', 'translate(0%,-100px)');

      });
      UIkit.util.on(el, 'show', function (e) {
        $('.modal-news .uk-modal-dialog').css('transform', 'translate(0%,0px)');
      });
    }

    elClose1.addEventListener('mouseenter', handler);
    elClose1.addEventListener('touchstart', handler);
    elClose2.addEventListener('mouseenter', handler);
    elClose2.addEventListener('touchstart', handler);

    // elClose1.addEventListener('mouseenter', function (e) {
    //   UIkit.util.on(el, 'hide', function (e) {
    //     $('.modal-news .uk-modal-dialog').css('transform','translate(0%,-100px)');

    //   });
    //   UIkit.util.on(el, 'show', function (e) {
    //     $('.modal-news .uk-modal-dialog').css('transform','translate(0%,0px)');
    //   });
    // });
    // elClose2.addEventListener('mouseenter', function (e) {
    //   UIkit.util.on(el, 'hide', function (e) {
    //     $('.modal-news .uk-modal-dialog').css('transform','translate(0%,-100px)');

    //   });
    //   UIkit.util.on(el, 'show', function (e) {
    //     $('.modal-news .uk-modal-dialog').css('transform','translate(0%,0px)');
    //   });
    // });

    elNextNews.addEventListener('mouseenter', function (e) {
      UIkit.util.on(el, 'hide', function (e) {
        el.querySelector('.uk-modal-dialog').style.transform = 'translate(-100%,0%)';
        elNextNewsModal.querySelector('.uk-modal-dialog').style.transform = 'translate(100%,0%)';
      });
      UIkit.util.on(elNextNewsHref, 'show', function (e) {
        elNextNewsModal.querySelector('.uk-modal-dialog').style.transform = 'translate(0%,0%)';
      });
    });
    elprevNews.addEventListener('mouseenter', function (e) {
      UIkit.util.on(el, 'hide', function (e) {
        el.querySelector('.uk-modal-dialog').style.transform = 'translate(100%,0%)';
        elprevNewsModal.querySelector('.uk-modal-dialog').style.transform = 'translate(-100%,0%)';
      });
      UIkit.util.on(elprevNewsHref, 'show', function (e) {
        elprevNewsModal.querySelector('.uk-modal-dialog').style.transform = 'translate(0%,0%)';
      });
    });
  });

  // Array.from(document.querySelectorAll('.news__slider .uk-card')).map(el => {
  //   el.addEventListener('click', function (e) {
  //     let elHref = el.getAttribute('href');
  //     const elModal = document.querySelector(elHref);
  //     Array.from(document.querySelectorAll('.uk-modal .uk-modal-dialog')).map(el => {
  //       el.style.transform = 'translate(0%,0%)';
  //     });



  //     elModal.querySelector('.modal-news__right').addEventListener('click', function(e) {
  //       console.log();
  //       const elNextModal = this.getAttribute('href');
  //       UIkit.util.on(elModal, 'beforehide', function (e) {
  //         elModal.querySelector('.uk-modal-dialog').style.transform = 'translate(-50%,0%)';
  //       });
  //     })
  //   });
  // });



  Array.from(document.querySelectorAll('.work-slider-js')).map(el => {
    const elSlider = el.querySelector('.work__slider');
    const elSliderNext = el.querySelector('.work-slider__arrow-right');
    const elSliderPrev = el.querySelector('.work-slider__arrow-left');
    const elSliderScrollbar = el.querySelector('.work-slider__scrollbar');

    const workSlider = new Swiper(elSlider, {
      spaceBetween: 20,
      slidesPerView: 1,

      navigation: {
        nextEl: elSliderNext,
        prevEl: elSliderPrev,
      },
      scrollbar: {
        el: elSliderScrollbar,
        hide: false,
        draggable: true
      },
      breakpoints: {
        // when window width is >= 320px
        639: {
          spaceBetween: 30,
          slidesPerView: 1.2,
        },
        959: {
          spaceBetween: 50,
          slidesPerView: 2.2,
        },
        1450: {
          spaceBetween: 50,
          slidesPerView: 3,

        },
      },
    });
  });


  var newsSlider = new Swiper(".news__slider", {
    spaceBetween: 20,
    slidesPerView: 1,

    navigation: {
      nextEl: ".news-slider__arrow-right",
      prevEl: ".news-slider__arrow-left",
    },
    scrollbar: {
      el: '.news-slider__scrollbar',
      hide: false,
      draggable: true
    },
    breakpoints: {
      // when window width is >= 320px
      639: {
        spaceBetween: 28,
        slidesPerView: 2,
      },
      1199: {
        // spaceBetween: 38,
        slidesPerView: 3,
      },
      1450: {
        spaceBetween: 40,
        slidesOffsetBefore: 0,
        slidesPerView: 3.7,

      },
    },
  });


  $('.marque-headline_1').marquee({
    //Если вы хотите всегда анимировать с помощью jQuery
    allowCss3Support: true,
    //работает, когда allowCss3Support имеет значение true - полный список см.http://www.w3.org/TR/2013/WD-css3-transitions-20131119/#transition-timing-function
    css3easing: 'linear',
    //Требуется плагин JQuery. По умолчанию это «линейный»
    easing: 'linear',
    //время паузы до следующего поворота анимации в миллисекундах
    delayBeforeStart: 200,
    //'left', 'right', 'up' or 'down'
    direction: 'left',
    //true или false - следует ли дублировать выделение, чтобы показать эффект продолжения потока
    duplicated: true,
    //скорость в миллисекундах
    duration: 70000,
    //разрыв в пикселях между тикерами
    gap: 0,
    //пауза
    pauseOnCycle: false,
    //при наведении курсора на паузу - с помощью плагина jQuery https://github.com/tobia/Pause
    // pauseOnHover: true,
    startVisible: true
  });


  if ($(window).width() > 1199) {
    Array.from(document.querySelectorAll('.js-parralax-1')).map(el => {
      window.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;
        el.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)';
      });
    });
    let bg = document.querySelector('.parralax-js');
    let bg2 = document.querySelector('.parralax-2-js');
    window.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      if (bg) {
        bg.style.transform = 'translate(-' + x * 100 + 'px, -' + y * 100 + 'px)';
      }
      if (bg2) {
        bg2.style.transform = 'translate(-' + x * 25 + 'px, -' + y * 25 + 'px)';
      }
    });
  }


  const sliderCounter = document.querySelectorAll('.slider__counter');
  sliderCounter.forEach(element => {
    //Счетчик слайда яхт
    UIkit.util.on(element, 'itemshown', function (e) {
      // $('#yacht__slider1 .slider_number').text(e.detail[0].index + 1);
      $(element).find('.slider_number').text(('0' + (e.detail[0].index + 1)).slice(0));

    });
  });



  function clock() {
    var d = new Date();
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();

    var month = new Array("января", "февраля", "марта", "апреля", "мая", "июня",
      "июля", "августа", "сентября", "октября", "ноября", "декабря");
    var month_en = new Array("january", "february", "marth", "april", "may", "june",
      "jule", "august", "september", "october", "november", "december");
    var days = new Array("Воскресенье", "Понедельник", "Вторник", "Среда",
      "Четверг", "Пятница", "Суббота");

    if (day <= 9) day = "0" + day;
    if (hours <= 9) hours = "0" + hours;
    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;

    var date_date = day + " " + month[d.getMonth()] + " " + d.getFullYear() + ' г.';
    var date_date2 = day + " " + month[d.getMonth()] + ' ';
    var date_date_en = day + " " + month_en[d.getMonth()] + " " + d.getFullYear() + ' y.';
    var date_time2 = hours + "<span class='anim-dott'>:</span>" + minutes + "<span class='anim-dott'>:</span>" + seconds;
    var date_time = hours + "<span class='anim-dott'>:</span>" + minutes;

    if (document.layers) {
      document.layers.date.document.write(date_time);
      document.layers.date.document.close();
      document.layers.time.document.write(date_time);
      document.layers.time.document.close();
    } else {
      if (document.getElementById("day")) {
        document.getElementById("day").innerHTML = date_date;
      }
      if (document.getElementById("day2")) {
        document.getElementById("day2").innerHTML = date_date2;
      }
      if (document.getElementById("day-en")) {
        document.getElementById("day-en").innerHTML = date_date_en;
      }
      if (document.getElementById("time")) {
        document.getElementById("time").innerHTML = date_time;
      }
      if (document.getElementById("time-menu")) {
        document.getElementById("time-menu").innerHTML = date_time;
      }
      if (document.getElementById("time2")) {
        document.getElementById("time2").innerHTML = date_time2;
      }


    }
    setTimeout(clock, 1000);
  }
  clock();


  // видео обычное
  $('.video-js').parent().click(function () {
    if ($(this).children(".video").get(0).paused) {
      $(this).children(".video").get(0).play();
      $(this).children(".playpause").fadeOut();
    } else {
      $(this).children(".video").get(0).pause();
      $(this).children(".playpause").fadeIn();
    }
  });

  // видео ютуб
  var video = {
    init: function () {
      video.play();
    },
    play: function () {
      $('body').on('click', '#play-video-btn', function (e) {
        video.setSrc(this);
      });
    },
    setSrc: function (el) {
      if (el) {
        var video_id = $(el).data('id');
        var url = '//www.youtube.com/embed/' + video_id + '?enablejsapi=1&version=3&showinfo=0&playerapiid=ytplayer&autoplay=1';
        $('img#embed-video-preview').hide();
        $('#play-video-btn').hide();
        $('iframe#embed-video-frame').attr('src', url).fadeIn();
      }
    }
  }
  video.init();




  // Маска телефона
  if ($('.f_phone').length > 0) {
    Inputmask({
      "mask": "+7 (999) 999-9999"
    }).mask('.f_phone');
  } else {
    console.log('not found')
  }

  let inputScrollNum = 1;
  // Инпут года
  const inputArchive = document.querySelectorAll('.input_archive .select');

  inputArchive.forEach(element => {

    const $this = $(element)
    const $styledSelect = $(element).find('.select-styled');
    const $listItems = $(element).find('li');
    const $list = $(element).find('.select-options');
    const inputScrollNumCurrent = inputScrollNum;

    $list.attr('id', `inputScroll${inputScrollNumCurrent}`)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), {
        alwaysShowTracks: true
      });
      inputScroll.update();
    });
    $listItems.click(function (e) {
      $styledSelect.text($(this).text()).removeClass('active');
      $('.select-options').fadeOut('fast');
    });
    $(document).on('touchstart mousedown', function (e) {
      if ($this.has(e.target).length === 0) {
        $list.fadeOut('fast');
        $styledSelect.removeClass('active');
      }
    });
    inputScrollNum++;

  });





  //  Стилизация селекта
  $('.input-js select').each(function () {
    let inputScrollNumCurrent = inputScrollNum;
    let $this = $(this),
      numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    let $styledSelect = $this.next('div.select-styled');
    // $styledSelect.text($this.attr('select_name'));
    $this.attr('select_name') != '' ? $styledSelect.text($this.attr('select_name')) : $styledSelect.text($('option:selected', this).text());

    let $list = $('<ul />', {
      'id': 'inputScroll' + inputScrollNumCurrent,
      'class': 'select-options'
    }).insertAfter($styledSelect);
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }
    let $listItems = $list.children('li');
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      console.log(inputScrollNumCurrent);
      if ($(`#inputScroll${inputScrollNumCurrent}`).height() >= 160) {
        let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), {
          alwaysShowTracks: true
        });
        inputScroll.update();
      }

    });
    $listItems.click(function (e) {
      $styledSelect.addClass('select-styled-black');
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      $("input[type='submit'].filterApply").trigger('click');
    });
    $(document).on('touchstart mousedown', function (e) {
      if ($.contains(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), e.target) === false) {
        $list.hide();
        $styledSelect.removeClass('active');
      }
    });
    inputScrollNum++;

  });





  //стилизация бургера меню
  function menuCheck() {
    var menu_attr = $("#offcanvas-nav").hasClass("uk-open");
    if (menu_attr) {
      $('.header_top_menu').addClass('open');
    } else {
      $('.header_top_menu').removeClass('open');
    };
  };

  UIkit.util.on('#offcanvas-nav', 'shown', function () {
    menuCheck();

  });
  UIkit.util.on('#offcanvas-nav', 'hidden', function () {
    menuCheck();
  });




  $(".menu-list a[uk-scroll]").click(function () {
    UIkit.offcanvas('#offcanvas-nav').hide();
  });



  $("a.transition").click(function (event) {
    event.preventDefault();
    var linkLocation = this.href;
    $("html").css('background', '#000');
    $("body").fadeOut(700, redirectPage);

    function redirectPage() {
      window.location = linkLocation;
    }
  });








  function getDataRentForm(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError2').text('* Вы не прошли проверку "Я не робот"');
    } else {
      // получаем элемент, содержащий капчу
      $('#recaptchaError2').text('');
      $.ajax({
        url: action,
        type: "POST",
        data: dataForm,
        success: function () {
          UIkit.modal('#modal-calling').hide();
          $('a[href="#modal-calling"]').attr('href', '#success')
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form').on('submit', getDataRentForm);

  function getDataRentForm2(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha2 = grecaptcha.getResponse(widgetId2);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha2.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError3').text('* Вы не прошли проверку "Я не робот"');
    } else {
      // получаем элемент, содержащий капчу
      $('#recaptchaError3').text('');
      $.ajax({
        url: action,
        type: "POST",
        data: dataForm,
        success: function () {
          UIkit.modal('#modal-calling2').hide();
          $('a[href="#modal-calling2"]').attr('href', '#success')
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form2').on('submit', getDataRentForm2);


});







// Анимация до якоря при переходе к другой странице
document.body.scrollTop = 0;
var windowScrolled = false;
$(document).ready(function (e) {
  if (windowScrolled != false || location.hash.length == 0) return;

  setTimeout(() => {
    var name = location.hash.substr(1);
    var name2 = name.split(';');

    if (name2[1] === undefined) {
      name2[1] = 0;
    }
    console.log(name);
    console.log(name2);
    var el = $('#' + name2[0] + ', [name=' + name2[0] + ']');

    console.log(el);

    if (el.length > 0) {
      $('body, html').animate({
        scrollTop: el.offset().top - name2[1]
      }, 1000);

      window.scrolled = true;
    }
  }, 1500);

});