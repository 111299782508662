/* eslint-disable */

//Функция создания карты сайта и затем вставки ее в блок с идентификатором &#34;map-yandex&#34;
function init() {
  if ($(window).width() < 640) {
    var myMapTemp = new ymaps.Map("map-yandex", {
      center: [59.926629, 30.341767], // координаты центра на карте
      zoom: 15, // коэффициент приближения карты
      controls: [] // выбираем только те функции, которые необходимы при использовании
    }),
      ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='map-controls'><span class='crow'></span><div id='zoom-in' class='zoom-in'><span class='icon-plus'>+</span></div><div id='zoom-out' class='zoom-out'><span class='icon-minus'>-</span></div></div>", {
        build: function () {
          ZoomLayout.superclass.build.call(this), this.zoomInCallback = ymaps.util.bind(this.zoomIn, this), this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this), $("#zoom-in").bind("click", this.zoomInCallback), $("#zoom-out").bind("click", this.zoomOutCallback)
        },
        clear: function () {
          $("#zoom-in").unbind("click", this.zoomInCallback), $("#zoom-out").unbind("click", this.zoomOutCallback), ZoomLayout.superclass.clear.call(this)
        },
        zoomIn: function () {
          var e = this.getData().control.getMap();
          e.setZoom(e.getZoom() + 1, {
            checkZoomRange: !0
          })
        },
        zoomOut: function () {
          var e = this.getData().control.getMap();
          e.setZoom(e.getZoom() - 1, {
            checkZoomRange: !0
          })
        }
      }),
      zoomControl = new ymaps.control.ZoomControl({
        options: {
          layout: ZoomLayout
        }
      });

    myMapTemp.controls.add(zoomControl, {
      float: "none",
      position: {
        top: 105,
        right: 15
      }
    });

    var mapEve = myMapTemp.panes.get("events");
    var mapElem = mapEve.getElement();
    myMapTemp.behaviors.disable("scrollZoom");
    myMapTemp.behaviors.disable('drag');
    ymaps.domEvent.manager.add(mapElem, "touchmove", function (e) {
      1 === e.get("touches").length && (mapElem.style.transition = "opacity .3s",
        mapElem.style.background = "rgba(0, 0, 0, .45)",
        mapElem.style.alignItems = "center",
        mapElem.style.boxSizing = "border-box",
        mapElem.style.color = "white",
        mapElem.style.display = "flex",
        mapElem.style.justifyContent = "center",
        mapElem.style.fontSize = "16px",
        mapElem.style.opacity = "0.0",
        mapElem.style.padding = "25px",
        mapElem.style.textAlign = "center",
        mapElem.style.transition = "opacity .3s",
        mapElem.style.touchAction = "auto",
        mapElem.textContent = 'Чтобы переместить карту проведите по ней двумя пальцами',
        mapElem.style.opacity = "1")
    });
    ymaps.domEvent.manager.add(mapElem, "touchend", function () {
      mapElem.style.transition = "opacity .8s",
        mapElem.style.opacity = "0"
    });

    var myPlacemarkWithContent2 = new ymaps.Placemark([59.926629, 30.341767], {
      //  hintContent: 'Посмотреть адрес',
      //  balloonContent: 'Московская обл. г. Подольск, Т.Ц. Красные ряды'
    }, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#imageWithContent',
      // Своё изображение иконки метки.
      iconImageHref: '../svg/svg-pin.svg',
      // Размеры метки.
      iconImageSize: [50, 50],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-25, -25],
      // Смещение слоя с содержимым относительно слоя с картинкой.
      iconContentOffset: [50, 50]
    });

  } else {
    var myMapTemp = new ymaps.Map("map-yandex", {
      center: [59.926629, 30.341767], // координаты центра на карте
      zoom: 15, // коэффициент приближения карты
      controls: [] // выбираем только те функции, которые необходимы при использовании
    }),


      ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='map-controls'><span class='crow'></span><div id='zoom-in' class='zoom-in'><span class='icon-plus'>+</span></div><div id='zoom-out' class='zoom-out'><span class='icon-minus'>-</span></div></div>", {
        build: function () {
          ZoomLayout.superclass.build.call(this), this.zoomInCallback = ymaps.util.bind(this.zoomIn, this), this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this), $("#zoom-in").bind("click", this.zoomInCallback), $("#zoom-out").bind("click", this.zoomOutCallback)
        },
        clear: function () {
          $("#zoom-in").unbind("click", this.zoomInCallback), $("#zoom-out").unbind("click", this.zoomOutCallback), ZoomLayout.superclass.clear.call(this)
        },
        zoomIn: function () {
          var e = this.getData().control.getMap();
          e.setZoom(e.getZoom() + 1, {
            checkZoomRange: !0
          })
        },
        zoomOut: function () {
          var e = this.getData().control.getMap();
          e.setZoom(e.getZoom() - 1, {
            checkZoomRange: !0
          })
        }
      }),
      zoomControl = new ymaps.control.ZoomControl({
        options: {
          layout: ZoomLayout
        }
      });

    myMapTemp.controls.add(zoomControl, {
      float: "none",
      position: {
        top: 220,
        right: 20
      }
    });
    myMapTemp.behaviors.disable("scrollZoom");

    var myPlacemarkWithContent2 = new ymaps.Placemark([59.926629, 30.341767], {
      //  hintContent: 'Посмотреть адрес',
      //  balloonContent: 'Московская обл. г. Подольск, Т.Ц. Красные ряды'
    }, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#imageWithContent',
      // Своё изображение иконки метки.
      iconImageHref: '../svg/svg-pin.svg',
      // Размеры метки.
      iconImageSize: [65, 65],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-32.5, -32.5],
      // Смещение слоя с содержимым относительно слоя с картинкой.
      iconContentOffset: [65, 65]
    });

  }

  

  myMapTemp.geoObjects.add(myPlacemarkWithContent2); // помещаем флажок на карту

}

// Функция загрузки API Яндекс.Карт по требованию (в нашем случае при наведении)
function loadScript(url, callback) {
  var script = document.createElement("script");

  if (script.readyState) {  // IE
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" ||
        script.readyState == "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {  // Другие браузеры
    script.onload = function () {
      callback();
    };
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

// Основная функция, которая проверяет когда мы навели на блок с классом &#34;ymap-container&#34;
export const ymap = function() {
  // Загружаем API Яндекс.Карт
  loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=d59af083-2176-46c1-b2d1-641dc27cbf75", function () {
    // Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
    ymaps.load(init);
  });
}



